import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import MdxCard from 'src/components/MdxCard';
import SEO from 'src/components/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Start new Gatsby.Js project" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h1 {...{
        "id": "start-new-gatsbyjs-project"
      }}>{`Start new Gatsby.Js project`}</h1>
      <p>{`Starting Pal.js UI from `}<a parentName="p" {...{
          "href": "https://github.com/paljs/gatsby-admin-template"
        }}>{`gatsby-admin-template`}</a>{` starter kit is the easiest way to run your first Pal.js UI application.`}</p>
      <p>{`Please note, that `}<strong parentName="p">{`gatsby-admin-template`}</strong>{` is just a frontend application. Backend integration can be done relatively simple, but you should be aware that all the data is mocked using JavaScript objects.
If you want the data to be dynamic, you should consider developing a backend integration by your own.
The Pal.js UI team doesn't consider providing generic integration layer as a part of this project because every backend API has a different structure in terms of data format and URLs.`}</p>
      <h2 {...{
        "id": "install-tools"
      }}>{`Install tools`}</h2>
      <p>{`To install gatsby-admin-template on your machine you need to have the following tools installed:`}</p>
      <ul>
        <li parentName="ul">{`Git - `}<a parentName="li" {...{
            "href": "https://git-scm.com"
          }}>{`https://git-scm.com`}</a></li>
        <li parentName="ul">{`Node.js - `}<a parentName="li" {...{
            "href": "https://nodejs.org"
          }}>{`https://nodejs.org`}</a>{` Please note the `}<strong parentName="li">{`version`}</strong>{` should be `}<strong parentName="li">{`>=8`}</strong></li>
        <li parentName="ul">{`Npm - Node.js package manager, comes with Node.js. Please make sure npm `}<strong parentName="li">{`version`}</strong>{` is `}<strong parentName="li">{`>=5`}</strong></li>
        <li parentName="ul">{`You might also need some specific native packages depending on your operating system like `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`build-essential`}</code>{` on Ubuntu`}</li>
      </ul>
      <div className="note note-warning">
  <div className="note-title">Warning!</div>
  <div className="note-body">
    Please note that <strong>it is not possible</strong> to build gatsby-admin-template
    <strong> without these tools</strong> and it will not be possible because of
    the way how React is built.
  </div>
      </div>
      <h2 {...{
        "id": "download-the-code"
      }}>{`Download the code`}</h2>
      <p>{`When you completed tools setup, you need to download the code of `}<strong parentName="p">{`gatsby-admin-template`}</strong>{` application. The easiest way to do that is to clone GitHub repository:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "bash"
      }}><pre parentName="div" {...{
          "className": "language-bash"
        }}><code parentName="pre" {...{
            "className": "language-bash"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`git`}</span>{` clone https://github.com/paljs/gatsby-admin-template.git`}</code></pre></div>
      <p>{`After the clone completed, you need to install npm modules:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "bash"
      }}><pre parentName="div" {...{
          "className": "language-bash"
        }}><code parentName="pre" {...{
            "className": "language-bash"
          }}><span parentName="code" {...{
              "className": "token builtin class-name"
            }}>{`cd`}</span>{` gatsby-admin-template `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`&&`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` i `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`||`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span></code></pre></div>
      <div className="note note-warning">
  <div className="note-title">Warning!</div>
  <div className="note-body">
    Please make sure that installation process successfully completed without
    errors.
  </div>
      </div>
      <h2 {...{
        "id": "run-local-copy"
      }}>{`Run local copy`}</h2>
      <p>{`To run a local copy in development mode, execute:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "bash"
      }}><pre parentName="div" {...{
          "className": "language-bash"
        }}><code parentName="pre" {...{
            "className": "language-bash"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` run dev `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`||`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{` dev`}</code></pre></div>
      <p>{`Go to `}<a parentName="p" {...{
          "href": "http://localhost:8000"
        }}>{`http://localhost:8000`}</a>{` in your browser.`}</p>
      <h2 {...{
        "id": "production-bundle"
      }}>{`Production bundle`}</h2>
      <p>{`To create a bundle in production mode, execute:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "bash"
      }}><pre parentName="div" {...{
          "className": "language-bash"
        }}><code parentName="pre" {...{
            "className": "language-bash"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` run build `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`||`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{` build`}</code></pre></div>
      <p>{`This will clear up your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`public`}</code>{` folder (where release files located) and generate a release build.
Now you can copy the sources from the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`public`}</code>{` folder and use it with any backend frameworks`}</p>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      